import { render, staticRenderFns } from "./Survey.vue?vue&type=template&id=379fbb54&scoped=true&"
import script from "./Survey.vue?vue&type=script&lang=ts&"
export * from "./Survey.vue?vue&type=script&lang=ts&"
import style0 from "./Survey.vue?vue&type=style&index=0&id=379fbb54&scoped=true&lang=css&"
import style1 from "./Survey.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379fbb54",
  null
  
)

export default component.exports